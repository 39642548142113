import NvWebsocket from './websocket/WebSocket';
import NvWebRTC from './webrtc/WebRTC';
import { generateUUID } from '../utils/misc';
import { logInfo, logError } from '../utils/logger';
import getAPIPath, { StreamType } from '../utils/apis';
import { StreamConfig } from '../utils/interfaces';
import { ErrorType, ErrorTypes } from '../utils/error';

const DEFAULT_INBOUND_VIDEO_ELEMENT_ID = 'tokkio-avatar-stream';
const DEFAULT_WEBSOCKET_ENDPOINT = `${
    window.location.protocol === 'https:' ? 'wss:' : 'ws:'
}//${window.location.host}/vms/ws`;

/** Define the App config. */
interface AppConfig {
    /** HTML video element IDs for inbound and outbound streams. */
    inboundStreamVideoElementId: string;
    outboundStreamVideoElementId?: string;
    /**
     * Connection ID for websocket connection. Same ID is used in tokkio use-case
     * for inbound and outbound streams.
     */
    connectionId?: string;

    /** Query parameters for webSocket connection. It should be in format param1=<>&param2=<> */
    queryParams?: string;
    /**
     * Enable websSocket ping functionality. Set webSocket ping interval.
     * A ping message will be sent every few second.
     */
    enableWebsocketPing?: boolean;
    websocketPingInterval?: number;
    /** Set VST webSocket endpoint. It should be format ws(s)://<ip>:<port>/<path> */
    vstWebsocketEndpoint: string;

    /** Enable-disable console logs for library. */
    enableLogs?: boolean;

    /**
     * Enable-Disable camera and microphone. If both are disabled then
     * outbound stream will not be started.
     */
    enableMicrophone?: boolean;
    enableCamera?: boolean;
    /**
     * Websocket timeout. If no message is received within this duration then
     * websocket connection will be closed.
     */
    websocketTimeoutMS?: number;

    streamType: StreamType;

    enableDummyUDPCall: boolean;

    /** Callback functions */
    sendCustomWebsocketMessage?: (msg: string) => boolean;
    firstFrameReceivedCallback?: () => void;
    errorCallback?: () => string;
    successCallback?: () => void;
    closeCallback?: () => void;
}

/** Stream manager class. Inbound and Outbound streams are controlled through this class */
export default class StreamManager {
    private websocket: NvWebsocket | null = null;
    private webrtc: NvWebRTC | null = null;
    private timerInterval: NodeJS.Timeout | null = null;
    private webSocketPingInterval: NodeJS.Timeout | null = null;
    private errorCallbackFired: boolean = false;
    private closeCallbackFired: boolean = false;
    private outboundStreamPeerId: string | null = null;
    private publicIPAddress: string | null = null;
    private inboundStreamPeerId: string | null = null;
    private isInboundConnectionSuccess: boolean = false;
    private isOutboundConnectionSuccess: boolean = false;
    private isProcessing: boolean = false;

    private appConfig: AppConfig;

    public streamType: StreamType = 'streambridge';
    public streamConfig: StreamConfig | null = null;

    constructor() {
        this.appConfig = StreamManager.getDefaultAppConfig();
        this.streamConfig = StreamManager.getDefaultStreamConfig();
    }

    /**
     *  Start the streaming. The function starts the webSocket connection. Once the
     * websocket goes into connected state onWebSocketConnected() will be triggered.
     *
     */
    public startStreaming(streamConfig: StreamConfig) {
        this.streamConfig = streamConfig;
        if (streamConfig) {
            this.streamConfig = {
                ...this.streamConfig,
                ...streamConfig,
            };
        }
        /** Return error if stream ID is not provided and camera-microphone is disabled. */
        if (
            !this.appConfig.enableCamera &&
            !this.appConfig.enableMicrophone &&
            !this.streamConfig.streamId
        ) {
            const errorType: ErrorType = ErrorTypes.INVALID_PARAMETER_ERROR;
            this.notifyError(errorType);
            return;
        }

        /** To handle case where callbacks can be triggered from multiple errors. */
        this.errorCallbackFired = true;
        this.closeCallbackFired = true;

        /** If a request on this instance is already in process then throw error. */
        if (this.isProcessing) {
            logError(this, 'A request is already in progress');
            const errorType: ErrorType = ErrorTypes.BUSY_ERROR;
            this.notifyError(errorType);
            return;
        }
        this.isProcessing = true;

        logInfo(this, 'new streaming process started');

        /** Try unmuting the video player. */
        this.unmuteVideoPlayer();
        /** Generate connectionId if not provided. */
        this.outboundStreamPeerId =
            this.appConfig.connectionId || generateUUID();

        /** Always print connection ID for tracking and debug purpose. */
        console.log('Connection ID: ', this.outboundStreamPeerId);

        /**
         * If both camera and microphone are disabled that means outbound stream
         * will not be instantiated. In that case no need to use different peer ID
         * for inbound peer connection.
         */
        if (this.appConfig.enableCamera || this.appConfig.enableMicrophone) {
            this.inboundStreamPeerId = `${this.outboundStreamPeerId}_1`;
        } else {
            this.inboundStreamPeerId = this.outboundStreamPeerId;
            this.outboundStreamPeerId = null;
        }

        /** Start WebSocket connection and wait for onWebSocketConnected() to trigger. */
        this.websocket = new NvWebsocket(
            this,
            this.outboundStreamPeerId || this.inboundStreamPeerId,
            this.appConfig.queryParams
        );
    }

    /** Stop streaming and do cleanup. */
    public async stopStreaming() {
        logInfo(this, 'Called stop streaming');
        await this.handleAppCleanup(
            'Cleanup called from stop streaming function in stream manager.'
        );
    }

    private static getDefaultAppConfig(): AppConfig {
        return {
            inboundStreamVideoElementId: DEFAULT_INBOUND_VIDEO_ELEMENT_ID,
            outboundStreamVideoElementId: undefined,
            connectionId: undefined,
            queryParams: '',
            enableWebsocketPing: true,
            websocketPingInterval: 2000,
            vstWebsocketEndpoint: DEFAULT_WEBSOCKET_ENDPOINT,
            enableLogs: true,
            enableMicrophone: true,
            enableCamera: true,
            websocketTimeoutMS: 5000,
            streamType: 'streambridge',
            enableDummyUDPCall: false,
            errorCallback: () => {
                return '';
            },
            successCallback: () => {},
            firstFrameReceivedCallback: () => {},
        };
    }

    private static getDefaultStreamConfig(): StreamConfig {
        return {
            streamId: undefined,
            startTime: undefined,
            endTime: undefined,
            options: {
                rtptransport: 'udp',
                timeout: 60,
                quality: 'auto',
            },
        };
    }

    public getConfig(): AppConfig {
        return { ...this.appConfig };
    }

    public getStreamConfig(): StreamConfig | null {
        return this.streamConfig;
    }

    public getInboundPeerConnectionObject() {
        if (this.webrtc && this.isInboundConnectionSuccess) {
            return this.webrtc.getInboundPeerConnectionObject();
        }
        return undefined;
    }

    public getOutboundPeerConnectionObject() {
        if (this.webrtc && this.isOutboundConnectionSuccess) {
            return this.webrtc.getInboundPeerConnectionObject();
        }
        return undefined;
    }

    public getInboundStreamPeerId() {
        return this.inboundStreamPeerId;
    }

    public getOutboundStreamPeerId() {
        return this.outboundStreamPeerId;
    }

    /** Clients can send any custom message over websocket connection. */
    public sendCustomWebsocketMessage(msg: string): boolean {
        if (this.websocket) {
            this.websocket.sendMessage(msg);
            return true;
        }
        return false;
    }

    /** Toggle microphone if Outbound Stream is connected. */
    public toggleMicrophone(): boolean | undefined {
        if (this.webrtc) {
            return this.webrtc.toggleMicrophone();
        }
        return undefined;
    }

    public setPublicIPAddress(ipAddress: string): void {
        this.publicIPAddress = ipAddress;
    }

    public getPublicIPAddress(): string | null {
        return this.publicIPAddress;
    }

    public getVSTWebSocketEndpoint(): string {
        return this.appConfig.vstWebsocketEndpoint;
    }

    private unmuteVideoPlayer(): void {
        const videoElement = document.getElementById(
            this.appConfig.inboundStreamVideoElementId || ''
        );
        if (videoElement) {
            logError(this, 'Unmute success');
            (videoElement as HTMLVideoElement).muted = false;
        } else {
            logError(this, 'Failed to unmute, video element not found');
        }
    }

    public updateConfig(newConfig: Partial<AppConfig>): void {
        this.appConfig = {
            ...this.appConfig,
            ...newConfig,
        };
        this.streamType = this.appConfig.streamType;
        logInfo(this, 'Updated config: ', this.appConfig);
    }

    /**
     * Do something after inbound stream is connected like
     * starting the outbound stream.
     */
    public onInboundStreamConnection(): void {
        logInfo(this, 'Inbound stream connected callback');
        if (this.appConfig.enableDummyUDPCall) {
            const dummyJson = {
                peerid: this.outboundStreamPeerId,
                apiKey: 'addDummyUdpTrack',
            };
            logInfo(this, 'Sending Dummy UDP call to VST');
            this.websocket?.sendMessage(JSON.stringify(dummyJson));
        }
    }

    /**
     * Websocket connect callback. Start WebRTC streaming after
     * websocket has been connected.
     */
    public onWebSocketConnected(): void {
        if (this.inboundStreamPeerId) {
            this.webrtc = new NvWebRTC(
                this,
                this.inboundStreamPeerId as string,
                this.outboundStreamPeerId as string
            );

            if (this.appConfig.enableWebsocketPing) {
                this.startWebSocketPing();
            }
        } else {
            logError(this, 'Outbound peer ID not generated');
        }
    }

    private startWebSocketPing(): void {
        this.webSocketPingInterval = setInterval(() => {
            const jsonData = {
                apiKey: getAPIPath(this.streamType).ping,
            };
            this.websocket?.sendMessage(JSON.stringify(jsonData));
        }, this.appConfig.websocketPingInterval);
    }

    private async notifyError(error: ErrorType) {
        if (this.errorCallbackFired) {
            if (this.appConfig.errorCallback) {
                logInfo(this, 'Error callback fired');
                this.appConfig.errorCallback();
            }
        }
        await this.handleAppCleanup('Cleanup called from notify error function');
    }

    private notifySuccess() {
        if (this.appConfig.successCallback) {
            logInfo(this, 'Success callback fired');
            this.appConfig.successCallback();
        }
    }

    public handleWebSocketMessage(message: any): void {
        if (this.webrtc) {
            this.webrtc.handleWebSocketMessage(message);
        }
    }

    public sendWebSocketMessage(message: string): void {
        if (this.websocket) {
            this.websocket.sendMessage(message);
        }
    }

    public handleWebRTCError(error: ErrorType): void {
        this.notifyError(error);
    }

    public handleWebSocketError(error: ErrorType): void {
        this.notifyError(error);
    }

    /**
     * If outbound stream is arleady connected OR outbound stream is disabled
     * then call the notifySuccess callback
     */
    public setInboundStreamConnectionStatus(status: boolean): void {
        this.isInboundConnectionSuccess = status;
        if (
            this.isOutboundConnectionSuccess ||
            (!this.appConfig.enableCamera && !this.appConfig.enableMicrophone)
        ) {
            this.notifySuccess();
        }
    }

    /** If inbound stream is already connected then call notifySuccess callback */
    public setOutboundStreamConnectionStatus(status: boolean): void {
        this.isOutboundConnectionSuccess = status;
        if (this.isInboundConnectionSuccess) {
            this.notifySuccess();
        }
    }

    public async handleAppCleanup(errorReason: string = 'default') {
        logInfo(this, 'Called handle App Cleanup', errorReason);
        /** Clear intervals */
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
        if (this.webSocketPingInterval) {
            clearInterval(this.webSocketPingInterval);
        }

        /** Clear webRTC and webSocket connection */
        if (this.webrtc) {
            this.webrtc.doCleanup();
        }
        if (this.websocket) {
            await this.websocket.close();
        }

        /** Call close callback */
        if (this.closeCallbackFired) {
            if (this.appConfig.closeCallback) {
                logInfo(this, 'Close callback fired');
                this.appConfig.closeCallback();
            }
        }
        /** Reset States */
        this.isInboundConnectionSuccess = false;
        this.isOutboundConnectionSuccess = false;

        this.websocket = null;
        this.webrtc = null;

        this.outboundStreamPeerId = null;
        this.inboundStreamPeerId = null;

        this.timerInterval = null;
        this.webSocketPingInterval = null;
        this.publicIPAddress = null;
        this.isProcessing = false;

        this.errorCallbackFired = false;
        this.closeCallbackFired = false;

        logInfo(this, 'listening for new processes');
    }
}
