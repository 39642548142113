export const ErrorTypes = {
    GET_USER_MEDIA_ERROR: {
        code: 1,
        message: 'Failed to access microphone or camera',
    },
    INBOUND_STREAM_ERROR: {
        code: 2,
        message: 'Failed to start inbound stream',
    },
    OUTBOUND_STREAM_ERROR: {
        code: 3,
        message: 'Failed to start outbound stream',
    },
    WEBSOCKET_ERROR: {
        code: 4,
        message: 'Failed to start websocket connection',
    },
    BUSY_ERROR: {
        code: 5,
        message: 'Previous streaming request is under processing'
    },
    INVALID_PARAMETER_ERROR: {
        code: 5,
        message: 'One or more invalid parameter provided'
    }
} as const;

export type ErrorType = (typeof ErrorTypes)[keyof typeof ErrorTypes];
export type ErrorCode = ErrorType['code'];
export type ErrorMessage = ErrorType['message'];
