import { IceServer, BitrateSettings } from '../../utils/interfaces';
import StreamManager from '../StreamManager';
import getAPIPath, { StreamType } from '../../utils/apis';
import { logInfo, logError } from '../../utils/logger';
import { getPublicIPAddress } from '../../utils/trickleICE';
import { ErrorType, ErrorTypes } from '../../utils/error';

/**
 * Watch dog for WebRTC connection so that we don't wait forever for WebRTC
 * to into connected state.
 */
const WATCH_DOG_TIMER = 45000;

/** Video stream width and height for getUserMedia API */
const HD_WIDTH = 1280;
const HD_HEIGHT = 720;
const FHD_WIDTH = 1920;
const FHD_HEIGHT = 1080;
const THIRTY_FPS = 30;

/** Class to handle both inbound and outbound WebRTC peer connections */
export default class NvWebRTC {
    private inboundMediaStream: MediaStream | null = null;
    private outboundMediaStream: MediaStream | null = null;

    private inboundPeerId: string;
    private outboundPeerId: string;

    private inboundPeerConnection: RTCPeerConnection | null = null;
    private outboundPeerConnection: RTCPeerConnection | null = null;

    private inboundEarlyCandidates: RTCIceCandidate[] = [];
    private outboundEarlyCandidates: RTCIceCandidate[] = [];

    private inboundICEServers: IceServer[] | null = null;
    private outboundICEServers: IceServer[] | null = null;

    private isInboundConnected: boolean = false;
    private isOutboundConnected: boolean = false;

    private inboundMinBitrate: number | null = null;
    private inboundMaxBitrate: number | null = null;
    private inboundStartBitrate: number | null = null;

    private inboundPublicIPAddress!: string | null;
    private outboundPublicIPAddress!: string | null;

    private inboundConnectionWatchDog: NodeJS.Timeout | null = null;
    private outboundConnectionWatchDog: NodeJS.Timeout | null = null;

    private streamManager: StreamManager;
    private streamType: StreamType;

    private degradationPreferenceForOutboundStream: string | null = null;

    constructor(
        streamManager: StreamManager,
        inboundPeerId: string,
        outboundPeerId: string
    ) {
        this.streamManager = streamManager;

        this.inboundPeerId = inboundPeerId;
        this.outboundPeerId = outboundPeerId;

        this.streamType = this.streamManager.streamType;
        this.initiateStreams();
    }

    private initiateStreams(): void {
        this.startWatchDog();

        Promise.all([
            new Promise<void>((resolve) => {
                this.initiateInboundWebRTCConnection();
                resolve();
            }),
            new Promise<void>((resolve) => {
                if (
                    this.streamManager.getConfig().enableCamera ||
                    this.streamManager.getConfig().enableMicrophone
                ) {
                    this.initiateOutboundWebrtcConnection();
                }
                resolve();
            }),
        ])
            .then(() => {
                logInfo(this.streamManager, 'Both streams have been created *');
                // Any code that depends on both streams being initialized can go here
            })
            .catch((error) => {
                logError(this.streamManager, 'Error creating streams:', error);
            });
    }

    public handleWebSocketMessage(msg: string): void {
        this.handleWebSocketMessageForInboundStream(msg);
        this.handleWebSocketMessageForOutboundStream(msg);
    }

    /**
     * *****************************************
     * Start of Inbound stream functions
     * *****************************************
     */

    async handleWebSocketMessageForInboundStream(msg: string): Promise<void> {
        try {
            const jsonData = JSON.parse(msg);
            if (
                typeof jsonData === 'object' &&
                Object.prototype.hasOwnProperty.call(jsonData, 'apiKey')
            ) {
                switch (jsonData.apiKey) {
                    case getAPIPath(this.streamType).ping:
                        // ignore the ping message
                        break;
                    case getAPIPath(this.streamType).startStream:
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received SDP offer for Inbound connection'
                            );
                            this.handleSDPOfferForInboundStream(jsonData.data);
                        }
                        break;
                    case getAPIPath(this.streamType).setAnswer:
                        // Unreal Engine Case
                        if (
                            Object.prototype.hasOwnProperty.call(
                                jsonData,
                                'data'
                            ) &&
                            Object.prototype.hasOwnProperty.call(
                                jsonData.data,
                                'wait_for_offer'
                            )
                        ) {
                            logInfo(this.streamManager, 'Waiting for offer...');
                            // perform webrtcCleanup of old connections if exist
                            logInfo(
                                this.streamManager,
                                'Cleanup inbound connections started'
                            );
                            if (this.inboundPeerConnection) {
                                this.inboundPeerConnection.close();
                                this.inboundPeerConnection = null;
                            }
                            this.inboundEarlyCandidates = [];
                            logInfo(
                                this.streamManager,
                                'Cleanup inbound connections success, return'
                            );
                            return;
                        }
                        if (
                            Object.prototype.hasOwnProperty.call(
                                jsonData,
                                'data'
                            ) &&
                            Object.prototype.hasOwnProperty.call(
                                jsonData,
                                'peerId'
                            ) &&
                            jsonData.peerId === this.inboundPeerId
                        ) {
                            // this is response of stream/start call
                            logInfo(
                                this.streamManager,
                                'Inbound  Stream ----> ',
                                'Received SDP answer for Inbound connection'
                            );
                            this.addInboundStreamDataListener();
                            this.setInboundRemoteDescription(jsonData.data);
                        }
                        break;
                    case getAPIPath(this.streamType).iceCandidate:
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received ICE candidates for Inbound connection'
                            );
                            this.processReceivedInboundICECandidate(
                                jsonData.data
                            );
                        }
                        break;
                    case getAPIPath(this.streamType).iceServers:
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received ICE servers for Inbound connection'
                            );
                            if (jsonData.data.iceServers) {
                                logInfo(
                                    this.streamManager,
                                    'Inbound Stream ----> ',
                                    'ICE servers list: ',
                                    jsonData.data.iceServers
                                );
                                this.inboundICEServers =
                                    jsonData.data.iceServers;
                                logInfo(
                                    this.streamManager,
                                    'Inbound Stream ----> ',
                                    'Getting public IP address '
                                );
                                await this.getInboundPublicAddress(
                                    jsonData.data
                                );
                                this.createRTCPeerConnectionForInboundStream(
                                    jsonData.data.iceServers
                                );
                            }
                        }
                        break;
                    case getAPIPath(this.streamType).configuration:
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received configuration for Inbound connection'
                            );
                            const { bitrate_min, bitrate_max, bitrate_start } =
                                this.getBitrateSettings(jsonData.data);
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'bitrate min: ',
                                bitrate_min
                            );
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'bitrate max: ',
                                bitrate_max
                            );
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'bitrate start: ',
                                bitrate_start
                            );
                            this.inboundMinBitrate = bitrate_min;
                            this.inboundMaxBitrate = bitrate_max;
                            this.inboundStartBitrate = bitrate_start;
                            this.getIceServersForInboundStream();
                        }
                        break;
                    default:
                        logError(
                            this.streamManager,
                            'Unknown apiKey:',
                            jsonData.apiKey
                        );
                }
            }
        } catch (error) {
            logError(
                this.streamManager,
                'Failed to handle WebSocket message',
                error
            );
        }
    }

    private startWatchDog(): void {
        this.inboundConnectionWatchDog = setTimeout(() => {
            if (this.isInboundConnected == false) {
                const errorType: ErrorType = ErrorTypes.INBOUND_STREAM_ERROR;
                this.streamManager.handleWebRTCError(errorType);
            }
        }, WATCH_DOG_TIMER);

        if (
            this.streamManager.getConfig().enableCamera ||
            this.streamManager.getConfig().enableMicrophone
        ) {
            this.outboundConnectionWatchDog = setTimeout(() => {
                if (this.isOutboundConnected == false) {
                    const errorType: ErrorType =
                        ErrorTypes.OUTBOUND_STREAM_ERROR;
                    this.streamManager.handleWebRTCError(errorType);
                }
            }, WATCH_DOG_TIMER);
        }
    }

    private addInboundStreamDataListener(): void {
        const videoElement = document.getElementById(
            this.streamManager.getConfig().inboundStreamVideoElementId
        );
        if (videoElement && !videoElement.hasAttribute('data-listener-added')) {
            videoElement.setAttribute('data-listener-added', 'true');
            videoElement.addEventListener('loadeddata', () => {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Data has started coming in. The first frame is loaded.'
                );
                // callback when first frame is received. To be used to show loading GIF
                const callback =
                    this.streamManager.getConfig().firstFrameReceivedCallback;
                if (callback) {
                    callback();
                }
            });
        }
    }

    private async getInboundPublicAddress(iceServers: any): Promise<void> {
        this.inboundPublicIPAddress = await getPublicIPAddress(
            this.streamManager,
            iceServers
        );
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Public IP address is',
            this.inboundPublicIPAddress
        );
        if (this.inboundPublicIPAddress) {
            this.streamManager.setPublicIPAddress(this.inboundPublicIPAddress);
        }
    }

    private async handleSDPOfferForInboundStream(sdpOffer: any): Promise<void> {
        if (!sdpOffer || !sdpOffer.sessionDescription || !sdpOffer.streamId) {
            logInfo(this.streamManager, 'Received incomplete data, skipping');
            return;
        }
        this.startInboundPeerConnection(
            this.inboundICEServers,
            sdpOffer.sessionDescription
        );
    }

    private startInboundPeerConnection(
        iceServers: IceServer[] | null,
        extendedOffer: RTCSessionDescriptionInit
    ): void {
        this.createRTCPeerConnectionForInboundStream(iceServers, false);
        this.processInboundSDPOffer(extendedOffer);
    }

    private processInboundICECandidate(candidate: RTCIceCandidateInit): void {
        if (candidate) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                `Adding ICE candidate - :${JSON.stringify(candidate)}`
            );
            this.inboundPeerConnection
                ?.addIceCandidate(candidate)
                .then(() => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        `addIceCandidate OK`
                    );
                })
                .catch((error) => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        `addIceCandidate error`,
                        error
                    );
                });
        }
    }

    private processInboundSDPOffer(
        extendedOffer: RTCSessionDescriptionInit
    ): void {
        if (this.inboundPeerConnection) {
            logInfo(this.streamManager, 'SDP Offer: ', extendedOffer);
            this.inboundPeerConnection
                .setRemoteDescription(new RTCSessionDescription(extendedOffer))
                .then(() => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        'setRemoteDescription complete, creating answer'
                    );
                    return this.inboundPeerConnection?.createAnswer();
                })
                .then((sessionDescription) => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        'createAnswer complete, setLocalDescription'
                    );
                    return this.inboundPeerConnection?.setLocalDescription(
                        sessionDescription
                    );
                })
                .then(() => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        '**** Adding remote early candidates *****',
                        this.inboundEarlyCandidates
                    );
                    this.inboundEarlyCandidates.forEach(
                        this.processInboundICECandidate.bind(this)
                    );
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        'setLocalDescription complete, sendAnswer'
                    );
                    const answerPayload = {
                        apiKey: getAPIPath(this.streamType).setAnswer,
                        peerId: this.inboundPeerId,
                        data: {
                            sessionDescription:
                                this.inboundPeerConnection?.localDescription,
                            peerId: this.inboundPeerId,
                        },
                    };
                    const jsonString = JSON.stringify(answerPayload);
                    this.streamManager.sendWebSocketMessage(jsonString);
                })
                .catch((e) => {
                    logError(
                        this.streamManager,
                        'Error during offer handling: ',
                        e
                    );
                });
        }
    }

    private getInboundVstConfig(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Calling getInboundVstConfig'
        );
        const jsonData = {
            apiKey: getAPIPath(this.streamType).configuration,
            data: null,
            peerId: this.inboundPeerId,
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private addIceCandidateForInboundStream(
        candidate: RTCIceCandidateInit
    ): void {
        const jsonData = {
            apiKey: getAPIPath(this.streamType).iceCandidate,
            peerId: this.inboundPeerId,
            data: candidate,
        };
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'calling /v1/iceCandidate with ',
            jsonData
        );
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private inboundOnICECandidate(event: RTCPeerConnectionIceEvent): void {
        if (!event.candidate) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'received null candidate, that means its the last candidate - client is chromium based'
            );
            return;
        }
        if (event.candidate.candidate.length === 0) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Received empty string for candidate - client is firefox'
            );
            return;
        }
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'received candidate inside onIceCandidate callback: ',
            event.candidate.candidate
        );
        if (event.candidate.type === 'srflx') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'The STUN server is reachable for this candidate!'
            );
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                `Public IP Address is: ${event.candidate.address}`
            );
        }
        if (event.candidate.type === 'relay') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'The TURN server is reachable for this candidate!'
            );
        }
        if (
            this.inboundPeerConnection &&
            this.inboundPeerConnection.currentRemoteDescription
        ) {
            this.addIceCandidateForInboundStream(event.candidate);
        } else {
            this.inboundEarlyCandidates.push(event.candidate);
        }
    }

    private rewriteSdp(
        sdp: RTCSessionDescriptionInit
    ): RTCSessionDescriptionInit {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Rewriting SDP with bitrates'
        );
        if (
            this.inboundMaxBitrate &&
            this.inboundMinBitrate &&
            this.inboundStartBitrate
        ) {
            const sdpStringFind = 'a=fmtp:(.*) (.*)';
            const sdpStringReplace = `a=fmtp:$1 $2;x-google-max-bitrate=${this.inboundMaxBitrate};x-google-min-bitrate=${this.inboundMinBitrate};x-google-start-bitrate=${this.inboundStartBitrate}`;
            let newSDP = sdp.sdp?.toString();
            newSDP = newSDP?.replace(
                new RegExp(sdpStringFind, 'g'),
                sdpStringReplace
            );
            sdp.sdp = newSDP;
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'using modified SDP Answer: ',
                sdp
            );
        }
        return sdp;
    }

    private processReceivedInboundICECandidate(
        candidates: RTCIceCandidateInit[]
    ): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            `Received candidates from VMS: ${JSON.stringify(candidates)}`
        );
        if (candidates) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Creating RTCIceCandidate from each received candidate..'
            );
            for (let i = 0; i < candidates.length; i += 1) {
                const candidate = candidates[i];
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    `Adding ICE candidate - ${i} :${JSON.stringify(candidate)}`
                );
                this.inboundPeerConnection
                    ?.addIceCandidate(candidate)
                    .then(() => {
                        logInfo(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            `addIceCandidate OK - ${i}`
                        );
                    })
                    .catch((error) => {
                        logInfo(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            `addIceCandidate error - ${i}`,
                            error
                        );
                    });
            }
        }
    }

    private async onInboundICEConnectionStateChange() {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'ice connection state change: ',
            this.inboundPeerConnection?.iceConnectionState || ''
        );
        if (this.inboundPeerConnection?.iceConnectionState === 'new') {
            // Candidates will come async over websocket
        }
        if (this.inboundPeerConnection?.iceConnectionState === 'connected') {
            const statusPayload = {
                apiKey: getAPIPath(this.streamType).peerConnectionStatus,
                peerId: this.inboundPeerId,
                data: { status: 'connected', peerId: this.inboundPeerId },
            };
            this.isInboundConnected = true;
            const jsonString = JSON.stringify(statusPayload);
            this.streamManager.sendWebSocketMessage(jsonString);
            this.streamManager.onInboundStreamConnection();
            this.streamManager.setInboundStreamConnectionStatus(true);
        }
        if (this.inboundPeerConnection?.iceConnectionState === 'disconnected') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            const statusPayload = {
                apiKey: getAPIPath(this.streamType).peerConnectionStatus,
                peerId: this.inboundPeerId,
                data: { status: 'disconnected', peerId: this.inboundPeerId },
            };
            const jsonString = JSON.stringify(statusPayload);
            this.streamManager.sendWebSocketMessage(jsonString);
            await this.streamManager.handleAppCleanup(
                'Cleanup called from Inbound disconnected state'
            );
        }
        if (this.inboundPeerConnection?.iceConnectionState === 'failed') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            await this.streamManager.handleAppCleanup(
                'Cleanup called from Inbound failed state'
            );
        }
    }

    private inboundOnICECandidateError(e: any): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'onIceCandidateError: ',
            e
        );
        if (e.errorCode !== 701) {
            logError(
                this.streamManager,
                `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
                'onIceCandidateError'
            );
        }
        if (e.errorCode === 701) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'error code is 701 that means DNS failed for ipv6, harmless error'
            );
        }
    }

    private onInboundICEGatheringStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'gathering state change: ',
            this.inboundPeerConnection?.iceGatheringState || ''
        );
    }

    private onInboundSignalingStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'signaling state change: ',
            this.inboundPeerConnection?.signalingState || ''
        );
    }

    private onInboundConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'connection state change: ',
            this.inboundPeerConnection?.connectionState || ''
        );
        if (this.inboundPeerConnection?.connectionState === 'disconnected') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Lost peer connection...'
            );
        }
    }

    private setInboundRemoteDescription(
        sessionDescriptionAnswer: RTCSessionDescriptionInit
    ): void {
        if (this.inboundPeerConnection) {
            this.inboundPeerConnection
                .setRemoteDescription(
                    new RTCSessionDescription(sessionDescriptionAnswer)
                )
                .then(() => {
                    this.inboundEarlyCandidates.forEach(
                        this.addIceCandidateForInboundStream.bind(this)
                    );
                })
                .catch((e) => {
                    logError(
                        this.streamManager,
                        'Failed to set remote description',
                        e
                    );
                });
        } else {
            logError(
                this.streamManager,
                'Failed to set remote description, no peer connection found'
            );
        }
    }

    private sendInboundSessionDescriptionToVst(
        sessionDescription: RTCSessionDescriptionInit
    ): void {
        const streamId = this.streamManager.getStreamConfig()?.streamId;
        interface SessionDescriptionPayload {
            [key: string]: any;
        }
        let sessionDescriptionPayload: SessionDescriptionPayload = {
            apiKey: getAPIPath(this.streamType).startStream,
            peerId: this.inboundPeerId,
            data: {
                clientIpAddr: this.inboundPublicIPAddress,
                peerId: this.inboundPeerId,
                sessionDescription,
                options: { quality: 'auto', rtptransport: 'udp', timeout: 60 },
            },
        };

        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'stream/start payload',
            sessionDescriptionPayload
        );

        if (this.streamManager.streamConfig?.streamId) {
            sessionDescriptionPayload.data.streamId =
                this.streamManager.streamConfig?.streamId;
        }
        if (this.streamManager.streamConfig?.startTime) {
            sessionDescriptionPayload.data.startTime =
                this.streamManager.streamConfig?.startTime;
        }
        if (this.streamManager.streamConfig?.endTime) {
            sessionDescriptionPayload.data.endTime =
                this.streamManager.streamConfig?.endTime;
        }
        if (this.streamManager.streamConfig?.options) {
            sessionDescriptionPayload.data.options =
                this.streamManager.streamConfig?.options;
        }

        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Payload of stream start: ',
            sessionDescriptionPayload
        );
        const jsonString = JSON.stringify(sessionDescriptionPayload);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private createOfferForInboundStream(): void {
        logInfo(this.streamManager, 'Inbound Stream ----> ', 'Creating Offer');
        this.inboundPeerConnection?.addTransceiver('audio', {
            direction: 'recvonly',
        });
        this.inboundPeerConnection?.addTransceiver('video', {
            direction: 'recvonly',
        });
        logInfo(this.streamManager, 'Offer to recvonly');
        this.inboundPeerConnection
            ?.createOffer()
            .then((sessionDescription) => {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'session Description with bitrates',
                    sessionDescription
                );
                this.inboundPeerConnection?.setLocalDescription(
                    sessionDescription
                );
                sessionDescription = this.rewriteSdp(sessionDescription);
                return sessionDescription;
            })
            .then((sessionDescription) => {
                this.sendInboundSessionDescriptionToVst(sessionDescription);
            })
            .catch((error) => {
                logError(this.streamManager, 'Failed to create offer', error);
            });
    }

    private createRTCPeerConnectionForInboundStream(
        iceServerList: IceServer[] | null,
        flag: boolean = true
    ): void {
        const rtcConfiguration: RTCConfiguration = {
            iceServers:
                iceServerList && iceServerList.length > 0 ? iceServerList : [],
        };
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'createRTCPeerConnectionForInboundStream'
        );
        try {
            this.inboundPeerConnection = new RTCPeerConnection(
                rtcConfiguration
            );
            this.inboundPeerConnection.onicecandidate =
                this.inboundOnICECandidate.bind(this);
            this.inboundPeerConnection.oniceconnectionstatechange =
                this.onInboundICEConnectionStateChange.bind(this);
            this.inboundPeerConnection.onicecandidateerror =
                this.inboundOnICECandidateError.bind(this);
            this.inboundPeerConnection.onicegatheringstatechange =
                this.onInboundICEGatheringStateChange.bind(this);
            this.inboundPeerConnection.onsignalingstatechange =
                this.onInboundSignalingStateChange.bind(this);
            this.inboundPeerConnection.onconnectionstatechange =
                this.onInboundConnectionStateChange.bind(this);
            this.inboundPeerConnection.ontrack = this.onInboundtrack.bind(this);
        } catch (error) {
            logError(
                this.streamManager,
                'Inbound Stream ----> ',
                `Failed to create RTC peer connection.`,
                error
            );
        }
        if (flag) {
            this.addInboundStreamDataListener();
            this.createOfferForInboundStream();
        }
    }

    private onInboundtrack(event: RTCTrackEvent) {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'on track called!'
        );
        const [stream] = event.streams;
        const videoElement = document.getElementById(
            this.streamManager.getConfig().inboundStreamVideoElementId
        ) as HTMLVideoElement;

        if (videoElement) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'stream received',
                stream
            );

            // Add video tracks to the mediaStream
            const videoTracks = stream.getVideoTracks();
            if (videoTracks.length > 0) {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Adding video track'
                );
                videoTracks.forEach((track) =>
                    this.inboundMediaStream?.addTrack(track)
                );
            }

            // Add audio tracks to the mediaStream
            const audioTracks = stream.getAudioTracks();
            if (audioTracks.length > 0) {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Adding audio track'
                );
                audioTracks.forEach((track) =>
                    this.inboundMediaStream?.addTrack(track)
                );
            }

            // Update the srcObject property of the video element with the combined mediaStream
            videoElement.srcObject = this.inboundMediaStream;

            // Attempt to play the video element
            const playPromise = videoElement.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        logInfo(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            'Autoplay with audio successful'
                        );
                        videoElement.muted = false;
                    })
                    .catch(() => {
                        // Handle autoplay failure
                        logError(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            'Autoplay with audio failed'
                        );
                        videoElement.muted = true; // Mute and try again
                        videoElement.play();
                    });
            }
        } else {
            logError(this.streamManager, 'Video element not found');
        }
    }

    private getIceServersForInboundStream(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Calling getIceServersForInboundStream',
            this.inboundPeerId
        );
        const jsonData = {
            apiKey: getAPIPath(this.streamType).iceServers,
            peerId: this.inboundPeerId,
            data: { peerId: this.inboundPeerId },
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private getBitrateSettings(config: any): BitrateSettings {
        try {
            const resolution = config.WebrtcOutDefaultResolution;
            const height = parseInt(resolution.split('x')[1], 10);
            let settings = null;
            switch (height) {
                case 480:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_480;
                    break;
                case 720:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_720;
                    break;
                case 1080:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_1080;
                    break;
                case 1440:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_1440;
                    break;
                case 2160:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_2160;
                    break;
                default:
                    throw new Error(`Unsupported resolution height: ${height}`);
            }
            const [bitrate_min, bitrate_max] = settings.bitrate_range;
            return {
                bitrate_min,
                bitrate_max,
                bitrate_start: settings.bitrate_start,
            };
        } catch (error) {
            logInfo(this.streamManager, 'Failed to do get bitrates');
            return {
                bitrate_min: null,
                bitrate_max: null,
                bitrate_start: null,
            };
        }
    }

    private initiateInboundWebRTCConnection(): void {
        this.getInboundVstConfig();
        this.inboundMediaStream = new MediaStream();
    }

    public getInboundPeerConnectionObject() {
        return this.inboundPeerConnection;
    }

    /**
     * *****************************************
     * End of Inbound stream functions
     * *****************************************
     */

    /**
     * *****************************************
     * Start of Outbound stream functions
     * *****************************************
     */

    async handleWebSocketMessageForOutboundStream(msg: string): Promise<void> {
        try {
            const jsonData = JSON.parse(msg);
            if (
                typeof jsonData === 'object' &&
                Object.prototype.hasOwnProperty.call(jsonData, 'apiKey')
            ) {
                switch (jsonData.apiKey) {
                    case getAPIPath(this.streamType).ping:
                        // ignore the ping message
                        break;
                    case getAPIPath(this.streamType).setAnswer:
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received SDP offer for Outbound connection'
                            );
                            this.setOutboundRemoteDescription(jsonData.data);
                        }
                        break;
                    case getAPIPath(this.streamType).iceCandidate:
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received ICE candidates for Outbound connection'
                            );
                            this.onReceiveCandidateForOutboundStream(
                                jsonData.data
                            );
                        }
                        break;
                    case getAPIPath(this.streamType).iceServers:
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received ICE servers for Outbound connection'
                            );
                            if (jsonData.data.iceServers) {
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'ICE servers list: ',
                                    jsonData.data.iceServers
                                );
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'Getting public IP address'
                                );
                                this.outboundPublicIPAddress =
                                    await getPublicIPAddress(
                                        this.streamManager,
                                        jsonData.data
                                    );
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'Public IP address',
                                    this.outboundPublicIPAddress
                                );
                                this.outboundICEServers =
                                    jsonData.data.iceServers;
                                this.createRTCPeerConnectionForOutboundStream(
                                    jsonData.data.iceServers
                                );
                            }
                        }
                        break;
                    case getAPIPath(this.streamType).configuration:
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received configuration for Outbound connection'
                            );
                            if (
                                jsonData.data.webrtcInVideoDegradationPreference
                            ) {
                                this.degradationPreferenceForOutboundStream =
                                    jsonData.data.webrtcInVideoDegradationPreference;
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'degradationPreference',
                                    this.degradationPreferenceForOutboundStream
                                );
                                this.setContentHint(this.outboundMediaStream);
                                this.getIceServersForOutboundStream();
                            }
                        }
                        break;
                    default:
                        logError(
                            this.streamManager,
                            'Unknown apiKey:',
                            jsonData.apiKey
                        );
                }
            }
        } catch (error) {
            logError(
                this.streamManager,
                'Failed to handle WebSocket message',
                error
            );
        }
    }

    // Toggle microphone. Returns boolean value true if microphone is enabled
    // and false if microphone is disabled. Returns undefined in case of error
    public toggleMicrophone = (): boolean | undefined => {
        if (this.outboundMediaStream) {
            logInfo(
                this.streamManager,
                'Outbound  Stream ----> ',
                'Toggling microphone'
            );
            try {
                const audoTrack = this.outboundMediaStream.getAudioTracks()[0];
                if (audoTrack) {
                    if (audoTrack.enabled) {
                        audoTrack.enabled = false;
                        logInfo(
                            this.streamManager,
                            'Outbound  Stream ----> ',
                            'microphone disabled'
                        );
                        return false;
                    } else {
                        audoTrack.enabled = true;
                        logInfo(
                            this.streamManager,
                            'Outbound  Stream ----> ',
                            'microphone enabled'
                        );
                        return true;
                    }
                }
            } catch (error) {
                logError(
                    this.streamManager,
                    'Outbound  Stream ----> ',
                    'Failed to toggle microphone',
                    error
                );
            }
        }
        logError(
            this.streamManager,
            'Outbound  Stream ----> ',
            'Media Stream not found'
        );
        return undefined;
    };

    private getOutboundVstConfig(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Calling getOutboundVstConfig'
        );
        const jsonData = {
            apiKey: getAPIPath(this.streamType).configuration,
            data: null,
            peerId: this.outboundPeerId,
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private addIceCandidateForOutboundStream(
        candidate: RTCIceCandidateInit
    ): void {
        const jsonData = {
            apiKey: getAPIPath(this.streamType).iceCandidate,
            peerId: this.outboundPeerId,
            data: candidate,
        };
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'calling /v1/iceCandidate with ',
            jsonData
        );
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private onIceCandidateForOutboundStream(
        event: RTCPeerConnectionIceEvent
    ): void {
        if (!event.candidate) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'received null candidate, that means its the last candidate - client is chromium based'
            );
            return;
        }
        if (event.candidate.candidate.length === 0) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Received empty string for candidate - client is firefox'
            );
            return;
        }
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'received candidate inside onIceCandidate callback: ',
            event.candidate.candidate
        );
        if (event.candidate.type === 'srflx') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'The STUN server is reachable for this candidate!'
            );
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                `Public IP Address is: ${event.candidate.address}`
            );
        }
        if (event.candidate.type === 'relay') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'The TURN server is reachable for this candidate!'
            );
        }
        if (
            this.outboundPeerConnection &&
            this.outboundPeerConnection.currentRemoteDescription
        ) {
            this.addIceCandidateForOutboundStream(event.candidate);
        } else {
            this.outboundEarlyCandidates.push(event.candidate);
        }
    }

    private onReceiveCandidateForOutboundStream(
        candidates: RTCIceCandidateInit[]
    ): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            `Received candidates from VMS: ${JSON.stringify(candidates)}`
        );
        if (candidates) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Creating RTCIceCandidate from each received candidate..'
            );
            for (let i = 0; i < candidates.length; i += 1) {
                const candidate = candidates[i];
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    `Adding ICE candidate - ${i} :${JSON.stringify(candidate)}`
                );
                this.outboundPeerConnection
                    ?.addIceCandidate(candidate)
                    .then(() => {
                        logInfo(
                            this.streamManager,
                            'Outbound Stream ----> ',
                            `addIceCandidate OK - ${i}`
                        );
                    })
                    .catch((error) => {
                        logInfo(
                            this.streamManager,
                            'Outbound Stream ----> ',
                            `addIceCandidate error - ${i}`,
                            error
                        );
                    });
            }
        }
    }

    private onOutboundIceConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'ice connection state change: ',
            this.outboundPeerConnection?.iceConnectionState || ''
        );
        if (this.outboundPeerConnection?.iceConnectionState === 'new') {
            // Candidates will come async over websocket
        }
        if (this.outboundPeerConnection?.iceConnectionState === 'connected') {
            // Handle connected state
            this.isOutboundConnected = true;
            this.streamManager.setOutboundStreamConnectionStatus(true);
        }
        if (
            this.outboundPeerConnection?.iceConnectionState === 'disconnected'
        ) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            const errorType: ErrorType = ErrorTypes.OUTBOUND_STREAM_ERROR;
            this.streamManager.handleWebRTCError(errorType);
        }
        if (this.outboundPeerConnection?.iceConnectionState === 'failed') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            const errorType: ErrorType = ErrorTypes.OUTBOUND_STREAM_ERROR;
            this.streamManager.handleWebRTCError(errorType);
        }
    }

    private onOutboundIceCandidateError(e: any): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'onOutboundIceCandidateError: ',
            e
        );
        if (e.errorCode !== 701) {
            logError(
                this.streamManager,
                `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
                'onOutboundIceCandidateError'
            );
        }
        if (e.errorCode === 701) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'error code is 701 that means DNS failed for ipv6, harmless error'
            );
        }
    }

    private onOutboundIceGatheringStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'gathering state change: ',
            this.outboundPeerConnection?.iceGatheringState || ''
        );
    }

    private onOutboundSignalingStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'signaling state change: ',
            this.outboundPeerConnection?.signalingState || ''
        );
    }

    private onOutboundConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'connection state change: ',
            this.outboundPeerConnection?.connectionState || ''
        );
        if (this.outboundPeerConnection?.connectionState === 'disconnected') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Lost peer connection...'
            );
        }
    }

    private setOutboundRemoteDescription(
        sessionDescriptionAnswer: RTCSessionDescriptionInit
    ): void {
        if (this.outboundPeerConnection) {
            this.outboundPeerConnection
                .setRemoteDescription(
                    new RTCSessionDescription(sessionDescriptionAnswer)
                )
                .then(() => {
                    this.outboundEarlyCandidates.forEach(
                        this.addIceCandidateForOutboundStream.bind(this)
                    );
                })
                .catch((e) => {
                    logError(
                        this.streamManager,
                        'Failed to set remote description',
                        e
                    );
                });
        } else {
            logError(
                this.streamManager,
                'Failed to set remote description, no peer connection found'
            );
        }
    }

    private sendOutboundSessionDescriptionToVst(
        sessionDescription: RTCSessionDescriptionInit
    ): void {
        const sessionDescriptionPayload = {
            apiKey: getAPIPath(this.streamType).startStream,
            peerId: this.outboundPeerId,
            data: {
                clientIpAddr: this.outboundPublicIPAddress,
                peerId: this.outboundPeerId,
                isClient: true,
                options: { quality: 'auto', rtptransport: 'udp', timeout: 60 },
                sessionDescription,
            },
        };
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'stream/start payload: ',
            sessionDescriptionPayload
        );
        const jsonString = JSON.stringify(sessionDescriptionPayload);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private createOfferForOutboundStream(): void {
        logInfo(this.streamManager, 'Outbound Stream ----> ', 'Creating Offer');
        this.outboundMediaStream
            ?.getTracks()
            .forEach((track) =>
                this.outboundPeerConnection?.addTrack(
                    track,
                    this.outboundMediaStream as MediaStream
                )
            );
        this.outboundPeerConnection?.addTransceiver('audio', {
            direction: 'sendonly',
        });
        this.outboundPeerConnection?.addTransceiver('video', {
            direction: 'sendonly',
        });
        logInfo(this.streamManager, 'Offer to sendonly');
        this.outboundPeerConnection
            ?.createOffer()
            .then((sessionDescription) => {
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'session Description with bitrates',
                    sessionDescription
                );
                this.outboundPeerConnection?.setLocalDescription(
                    sessionDescription
                );
                return sessionDescription;
            })
            .then((sessionDescription) => {
                this.sendOutboundSessionDescriptionToVst(sessionDescription);
            })
            .catch((error) => {
                logError(this.streamManager, 'Failed to create offer', error);
            });
    }

    private createRTCPeerConnectionForOutboundStream(
        iceServerList: IceServer[]
    ): void {
        const rtcConfiguration: RTCConfiguration = {
            iceServers: iceServerList,
        };
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'createRTCPeerConnectionForOutboundStream'
        );
        try {
            this.outboundPeerConnection = new RTCPeerConnection(
                rtcConfiguration
            );
            this.outboundPeerConnection.onicecandidate =
                this.onIceCandidateForOutboundStream.bind(this);
            this.outboundPeerConnection.oniceconnectionstatechange =
                this.onOutboundIceConnectionStateChange.bind(this);
            this.outboundPeerConnection.onicecandidateerror =
                this.onOutboundIceCandidateError.bind(this);
            this.outboundPeerConnection.onicegatheringstatechange =
                this.onOutboundIceGatheringStateChange.bind(this);
            this.outboundPeerConnection.onsignalingstatechange =
                this.onOutboundSignalingStateChange.bind(this);
            this.outboundPeerConnection.onconnectionstatechange =
                this.onOutboundConnectionStateChange.bind(this);
        } catch (error) {
            logError(
                this.streamManager,
                'Outbound Stream ----> ',
                `Failed to create RTC peer connection.`,
                error
            );
        }
        this.createOfferForOutboundStream();
    }

    private getIceServersForOutboundStream(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Calling getIceServersForOutboundStream',
            this.outboundPeerId
        );
        const jsonData = {
            apiKey: getAPIPath(this.streamType).iceServers,
            peerId: this.outboundPeerId,
            data: { peerId: this.outboundPeerId },
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private setContentHint(stream: MediaStream | null): void {
        if (!stream) return;

        const videoTracks = stream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();

        if (videoTracks.length > 0) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                `Using video device: ${videoTracks[0].label}`
            );
            videoTracks.forEach((track) => {
                if (this.degradationPreferenceForOutboundStream != null) {
                    logInfo(
                        this.streamManager,
                        'Outbound Stream ----> ',
                        'setting degradation preference: ',
                        this.degradationPreferenceForOutboundStream
                    );
                    track.contentHint =
                        this.degradationPreferenceForOutboundStream ===
                        'resolution'
                            ? 'motion'
                            : 'detail';
                } else {
                    logInfo(
                        this.streamManager,
                        'Outbound Stream ----> ',
                        'setting default degradation preference'
                    );
                    track.contentHint = 'detail';
                }
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'video track: ',
                    track
                );
            });
        }

        if (audioTracks.length > 0) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                `Using audio device: ${audioTracks[0].label}`
            );
            audioTracks.forEach((track) => {
                track.contentHint = 'speech';
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'audio track: ',
                    track
                );
            });
        }

        this.outboundMediaStream = stream;
    }

    private async initiateOutboundWebrtcConnection(): Promise<void> {
        try {
            // Initialize an empty constraints object
            const constraints: MediaStreamConstraints = {};

            // Conditionally add audio constraints
            if (this.streamManager.getConfig().enableMicrophone) {
                constraints.audio = { echoCancellation: true };
            }

            // Conditionally add video constraints
            if (this.streamManager.getConfig().enableCamera) {
                constraints.video = {
                    width: { min: HD_WIDTH, ideal: HD_WIDTH, max: FHD_WIDTH },
                    height: {
                        min: HD_HEIGHT,
                        ideal: HD_HEIGHT,
                        max: FHD_HEIGHT,
                    },
                    frameRate: { ideal: THIRTY_FPS, max: THIRTY_FPS },
                };
            }
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'getting user media with constraints',
                constraints
            );
            const stream = await navigator.mediaDevices.getUserMedia(
                constraints
            );
            this.outboundMediaStream = stream;
            const outboundStreamVideoElement =
                this.streamManager.getConfig().outboundStreamVideoElementId;
            if (outboundStreamVideoElement) {
                const videoElement = document.getElementById(
                    outboundStreamVideoElement
                ) as HTMLVideoElement | null;
                if (videoElement) {
                    videoElement.srcObject = stream;
                    logInfo(
                        this.streamManager,
                        'Outbound Stream ----> ',
                        'MediaStream: ',
                        this.outboundMediaStream
                    );
                }
            }
        } catch (error) {
            if (error instanceof DOMException) {
                switch (error.name) {
                    case 'NotFoundError':
                        logError(
                            this.streamManager,
                            'Error: No camera and/or microphone found.'
                        );
                        break;
                    case 'NotAllowedError':
                        logError(
                            this.streamManager,
                            'Error: Permission to use camera and/or microphone was denied.'
                        );
                        break;
                    case 'NotReadableError':
                        logError(
                            this.streamManager,
                            'Error: Could not access camera and/or microphone. The device may be in use by another application.'
                        );
                        break;
                    case 'OverconstrainedError':
                        logError(
                            this.streamManager,
                            'Error: The requested media settings are not supported by the device.'
                        );
                        break;
                    case 'AbortError':
                        logError(
                            this.streamManager,
                            'Error: The operation was aborted.'
                        );
                        break;
                    case 'SecurityError':
                        logError(
                            this.streamManager,
                            'Error: The operation is insecure or the page is not allowed to access media devices.'
                        );
                        break;
                    default:
                        logError(
                            this.streamManager,
                            `Error accessing media devices: ${error.name}`
                        );
                }
            } else {
                logError(
                    this.streamManager,
                    'An unexpected error occurred while trying to access media devices:',
                    error
                );
            }
            const errorType: ErrorType = ErrorTypes.GET_USER_MEDIA_ERROR;
            this.streamManager.handleWebRTCError(errorType);
            return;
        }
        this.getOutboundVstConfig();
    }

    public getOutboundPeerConnectionObject() {
        return this.outboundPeerConnection;
    }

    /**
     * *****************************************
     * End of Outbound stream functions
     * *****************************************
     */

    public doCleanup(): void {
        const inboundStreamCleanup = () => {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Cleanup media streams'
            );
            if (this.inboundConnectionWatchDog) {
                clearTimeout(this.inboundConnectionWatchDog);
            }
            if (this.outboundConnectionWatchDog) {
                clearTimeout(this.outboundConnectionWatchDog);
            }
            if (this.inboundMediaStream) {
                this.inboundMediaStream
                    .getTracks()
                    .forEach((track) => track.stop());
                this.inboundMediaStream = null;
            }

            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Cleanup inbound peer connection'
            );
            if (this.inboundPeerConnection) {
                const jsonPayload = {
                    apiKey: getAPIPath(this.streamType).stopStream,
                    peerId: this.inboundPeerId,
                    data: { peerId: this.inboundPeerId },
                };
                const jsonString = JSON.stringify(jsonPayload);
                this.streamManager.sendWebSocketMessage(jsonString);
                this.inboundPeerConnection.close();
                this.inboundPeerConnection = null;
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Set inbound peer connection to null'
                );
            }

            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Cleanup video element'
            );
            const videoElement = document.getElementById(
                this.streamManager.getConfig().inboundStreamVideoElementId
            ) as HTMLVideoElement | null;
            if (videoElement) {
                videoElement.srcObject = null;
                videoElement.load();
            }
            this.inboundEarlyCandidates = [];
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'cleanup done'
            );
        };

        const outboundStreamCleanup = () => {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Cleanup media streams'
            );
            if (this.outboundConnectionWatchDog) {
                clearTimeout(this.outboundConnectionWatchDog);
            }
            if (this.outboundMediaStream) {
                this.outboundMediaStream
                    .getTracks()
                    .forEach((track) => track.stop());
                this.outboundMediaStream = null;
            }

            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Cleanup outbound peer connection'
            );
            if (this.outboundPeerConnection) {
                const jsonPayload = {
                    apiKey: getAPIPath(this.streamType).stopStream,
                    peerId: this.outboundPeerId,
                    data: { peerId: this.outboundPeerId },
                };
                const jsonString = JSON.stringify(jsonPayload);
                this.streamManager.sendWebSocketMessage(jsonString);
                this.outboundPeerConnection.close();
                this.outboundPeerConnection = null;
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'Set outbound peer connection to null'
                );
            }

            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Cleanup video element'
            );
            const outboundStreamVideoElementId =
                this.streamManager.getConfig().outboundStreamVideoElementId;
            if (outboundStreamVideoElementId) {
                const videoElement = document.getElementById(
                    outboundStreamVideoElementId
                ) as HTMLVideoElement | null;
                if (videoElement) {
                    videoElement.srcObject = null;
                    videoElement.load();
                }
            }
            this.outboundEarlyCandidates = [];
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'cleanup done'
            );
        };

        inboundStreamCleanup();
        outboundStreamCleanup();
    }
}
