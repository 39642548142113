export type StreambridgeAPIs = {
    startStream: string;
    stopStream: string;
    iceServers: string;
    configuration: string;
    iceCandidate: string;
    setAnswer: string;
    peerConnectionStatus: string;
    ping: string;
};

export type LiveStreamAPIs = {
    startStream: string;
    stopStream: string;
    iceServers: string;
    configuration: string;
    iceCandidate: string;
    setAnswer: string;
    peerConnectionStatus: string;
    ping: string;
};

export type ReplayStreamAPIs = {
    startStream: string;
    stopStream: string;
    iceServers: string;
    configuration: string;
    iceCandidate: string;
    setAnswer: string;
    peerConnectionStatus: string;
    ping: string;
};

export type GenericAPIResponses = {
    startStream: string;
    stopStream: string;
    iceServers: string;
    configuration: string;
    iceCandidate: string;
    setAnswer: string;
    peerConnectionStatus: string;
    ping: string;
};

const APIPaths: {
    streambridge: StreambridgeAPIs;
    live: LiveStreamAPIs;
    replay: ReplayStreamAPIs;
} = {
    streambridge: {
        startStream: 'api/v1/streambridge/stream/start',
        stopStream: 'api/v1/streambridge/stream/stop',
        iceServers: 'api/v1/streambridge/iceServers',
        configuration: 'api/v1/streambridge/configuration',
        iceCandidate: 'api/v1/streambridge/iceCandidate',
        setAnswer: 'api/v1/streambridge/setAnswer',
        peerConnectionStatus: '/event/streambridge/ext/peerconnection/status',
        ping: 'api/v1/streambridge/ping'
    },
    live: {
        startStream: 'api/v1/live/stream/start',
        stopStream: 'api/v1/live/stream/stop',
        iceServers: 'api/v1/live/iceServers',
        configuration: 'api/v1/live/configuration',
        iceCandidate: 'api/v1/live/iceCandidate',
        setAnswer: 'api/v1/live/setAnswer',
        peerConnectionStatus: '',
        ping: 'api/v1/live/ping'
    },
    replay: {
        startStream: 'api/v1/replay/stream/start',
        stopStream: 'api/v1/replay/stream/stop',
        iceServers: 'api/v1/replay/iceServers',
        configuration: 'api/v1/replay/configuration',
        iceCandidate: 'api/v1/replay/iceCandidate',
        setAnswer: 'api/v1/replay/setAnswer',
        peerConnectionStatus: '',
        ping: 'api/v1/replay/ping'
    }
};

export type StreamType = 'streambridge' | 'live' | 'replay';

export default function getAPIPath(key : StreamType) {
    return APIPaths[key];
}
