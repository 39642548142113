import { ErrorType, ErrorTypes } from '../../utils/error';
import { logInfo, logError } from '../../utils/logger';
import StreamManager from '../StreamManager';

export default class NvWebSocket {
    private connectionId: string;
    private socket: WebSocket;
    private messageReceived: boolean;
    private messageReceivedTimeout: NodeJS.Timeout | null;
    private streamManager: StreamManager;

    constructor(
        streamManager: StreamManager,
        connectionId: string,
        queryParams = ''
    ) {
        this.streamManager = streamManager;
        this.connectionId = connectionId;

        const wsEndpoint = this.streamManager.getVSTWebSocketEndpoint();
        logInfo(this.streamManager, 'ws endpoint: ', wsEndpoint);
        const websocketEndpoint = new URL(wsEndpoint);
        websocketEndpoint.searchParams.set('connectionId', this.connectionId);
        if (queryParams) {
            websocketEndpoint.search += `&${queryParams}`;
        }
        const finalEndpoint = websocketEndpoint.toString();
        logInfo(
            this.streamManager,
            'ws endpoint with query params: ',
            finalEndpoint
        );

        this.messageReceived = false;
        this.messageReceivedTimeout = null;

        logInfo(this.streamManager, 'Starting WebSocket Connection');
        this.socket = new WebSocket(finalEndpoint);

        this.socket.onopen = this.onOpen.bind(this);
        this.socket.onclose = this.onClose.bind(this);
        this.socket.onerror = this.onError.bind(this);
        this.socket.onmessage = this.onMessage.bind(this);
    }

    private onOpen() {
        logInfo(this.streamManager, 'WebSocket connection established');
        const websocketTimeoutMS =
            this.streamManager.getConfig().websocketTimeoutMS;
        if (websocketTimeoutMS) {
            this.messageReceivedTimeout = setTimeout(async () => {
                if (this.messageReceived === false) {
                    this.socket.close();
                    const errorCallback =
                        this.streamManager.getConfig().errorCallback;
                    if (errorCallback) {
                        errorCallback();
                    }
                    if (this.streamManager) {
                        await this.streamManager.handleAppCleanup();
                    }
                }
                if (this.messageReceivedTimeout) {
                    clearTimeout(this.messageReceivedTimeout);
                }
            }, websocketTimeoutMS);
        }

        this.streamManager?.onWebSocketConnected();
    }

    private async onClose() {
        logInfo(this.streamManager, 'WebSocket connection closed');
        if (this.messageReceivedTimeout) {
            clearTimeout(this.messageReceivedTimeout);
        }
        await this.streamManager?.handleAppCleanup();
    }

    private onError(error: Event): void {
        logError(this.streamManager, 'WebSocket error:', error);
        if (this.messageReceivedTimeout) {
            clearTimeout(this.messageReceivedTimeout);
        }
        const errorType: ErrorType = ErrorTypes.WEBSOCKET_ERROR;
        this.streamManager?.handleWebSocketError(errorType);
    }

    private onMessage(event: MessageEvent): void {
        this.messageReceived = true;

        if (typeof event.data === 'string') {
            let websocketMessage: any;
            try {
                websocketMessage = JSON.parse(event.data);
            } catch (error) {
                logError(this.streamManager, error);
            }
            if (websocketMessage) {
                this.streamManager?.handleWebSocketMessage(event.data);
            }
        } else {
            logError(
                this.streamManager,
                'webSocket error - unsupported data type received'
            );
        }
    }

    public sendMessage(message: string): void {
        this.socket.send(message);
    }

    public close(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.messageReceivedTimeout) {
                clearTimeout(this.messageReceivedTimeout);
            }

            // Check if the WebSocket is already closed
            if (this.socket.readyState === WebSocket.CLOSED) {
                resolve();
                return;
            }

            // Add an event listener for the 'close' event
            this.socket.addEventListener('close', () => {
                resolve();
            });

            // Add an event listener for the 'error' event to handle any errors
            this.socket.addEventListener('error', (event) => {
                reject(
                    new Error('WebSocket encountered an error during close.')
                );
            });

            // Initiate the WebSocket close
            this.socket.close();
        });
    }
}
